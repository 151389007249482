.server {
    .serverName {
        position: relative;
        color: #a6a7ab;
        margin: 0;
        padding-right: 20px;
        font-size: 0.7rem;

        .info {
            position: absolute;
            height: 0.7rem;
            width: 0.7rem;
            right: 0.1rem;
        }
    }
}

td td {
    padding: 0 4px !important;
    margin: 0;
    border-top: none !important;
    border-left: none !important;
    border-bottom: none !important;
}