.block {
    width: 14px;
    height: 6px;
    border: 1px solid black;
    border-radius: 4px;
    display: inline-block;
    vertical-align: 0px;
    &:hover {
        border: 1px solid white;
    }
}