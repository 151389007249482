.txt, .txtRight {
    display: inline-block;
}

.txtRight {
    float: right;
    margin-left: 16px;
}

.copy{
    float: right;
}

.code {
    clear: both;
    font-family: monospace;
}

.blinking {
    background-color: hsl(162, 67%, 48%);
    animation: blink 1s infinite alternate;
}

.green {
    background-color: hsl(162, 67%, 48%);
}

.green-grey {
    background-color: hsl(162, 37%, 38%);
}

.yellow {
    background-color: hsl(60, 58%, 53%);
}

.yellow-grey {
    background-color: hsl(60, 51%, 40%);
}

.orange {
    background-color: hsl(34, 100%, 53%);
}

.orange-grey {
    background-color: hsl(34, 70%, 43%);
}

.red {
    background-color: hsl(356, 76%, 56%);
}

.red-grey {
    background-color: hsl(356, 46%, 46%);
}


@keyframes blink {
    0% {
        background: #29CC9A;
    }
    100% {
        background: transparent;
    }
}