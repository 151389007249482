body{
  background-image: linear-gradient(45deg, #0A202F, #002D4B);
  width: 100%;
  height: 100%;
}

html {
  overflow-y: scroll;
}

:root{
  --primary: #00b89c;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
  visibility: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007666;
}

* {
  scrollbar-color: var(--primary) #f1f1f1;
  scrollbar-width: thin;
}