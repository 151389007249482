.table {
    th {
        text-align: center !important;
    }
    td, th {
        &:last-child{
            border-right: none;
        }
    }
    td {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        line-height: 10px;
    }
}